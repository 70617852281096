// Import Libraries and Compoinents
import React, { Component } from 'react';
// import DropdownSelect from '../../../../components/dropdown/Dropdown';
import Table from '../../../../components/table/Table';
import moment from 'moment';
import MembershipTaskListProcess from "./MembershipTaskListProcess";
import { DateInput } from '../../../../components/allTypesInputComponents/AllTypeInputComponents';
import { validate, isValidForm } from "../../../../utils/validation/CommonValidator";
import { TaskListDetailsValidationRules } from "../../../../utils/validation/AllValidationRules";
import { CommonValidationMessages, CommonSuccessMessages } from "../../../../utils/Messages";
import SendPaymentLink from './SendPaymentLink';
import SendQuotationModal from './SendQuotationModal';
import EscalateModal from './EscalateModal';
import SendForApproveModal from './SendForApproveModal';
import SendForRejectModal from './individualTaskList/SendForRejectModal';
// import ActionContainer from "../../../../components/UIComponent/ActionTableComponent/actionContainer";
// import DropdownAction from "../../../../components/dropdown/DropdownAction";
import DropdownSelect from "../../../../components/dropdown/Dropdown";
import DropdownAction from "../../../../components/dropdown/DropdownAction";
import ActionContainer from "../../../../components/UIComponent/ActionTableComponent/actionContainer";
// Import API Services
import SwalServices from '../../../../services/swalServices/SwalServices';
import MembershipTaskListServices from "../../../../services/axiosServices/apiServices/MembershipTaskListServices";
import { saveAs } from 'file-saver';
import { Navigate } from 'react-router-dom';
import { encryptAES } from '../../../../utils/Encryption';
import { getAuthProps } from "../../../../utils/AuthenticationLibrary";

export default class MembershipTaskList extends Component {
    constructor(props) {
        super(props);
        this.swalServices = new SwalServices();
        this.membershipTaskListServices = new MembershipTaskListServices();
        this.state = {
            totalResultes: 0,
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                dateFrom: "",
                dateTo: "",
                filterBySource: 0,
                searchText: "",
                statusId: 0,
            },
            deactionDropdown: [ "View", "Delete"],
            actionDropdown: ["View", "Delete"],
            selectedTaskId: 0,
            setAddeditModalOpen: false,
            membershipTypeId: 1,
            isLoading: false,
            membershipTaskList: [
                // {
                //     RegistrationDate: "7-8-2025",
                //     Name: "Nisarg Patel",
                //     EmailAddress: "mol@1234",
                //     CompanyName: "Moltech",
                //     CompanyUEN: "Super Active",
                //     TransactionType: "Kacchi Babul 120",
                //     Source: "Honda Dada",
                // },
                // {
                //     RegistrationDate: "7-8-2025",
                //     Name: "Visarg Hatela",
                //     EmailAddress: "mol@1234",
                //     CompanyName: "Zoltech",
                //     CompanyUEN: "Active",
                //     TransactionType: "Nulla Nawab 420",
                //     Source: "KTM Chapari",
                // },
            ],
            statusId: [
                // {
                //     id: 2,
                //     name: "Submitted"
                // },
                // {
                //     id: 3,
                //     name: "Pending Approval"
                // },
            ],

            // Membership Task List Process State Data
            usersList: [],
            billingCategoryList: [
                //     {
                //         id: 1,
                //         name: "Billing Category 1"
                //     },
                //     {
                //         id: 2,
                //         name: "Billing Category 2"
                //     },
                //     {
                //         id: 3,
                //         name: "Billing Category 3"
                //     },
                //     {
                //         id: 4,
                //         name: "Billing Category 4"
                //     },
                //     {
                //         id: 5,
                //         name: "Billing Category 5"
                //     },
            ],
            updatedByList: [
                {
                    id: 1,
                    name: "Admin"
                },
                {
                    id: 2,
                    name: "Web"
                }
            ],
            membershipTypeList: [
                // {
                //     id: 1,
                //     name: "Membership Type 1"
                // },
                // {
                //     id: 2,
                //     name: "Membership Type 2"
                // },
                // {
                //     id: 3,
                //     name: "Membership Type 3"
                // },
                // {
                //     id: 4,
                //     name: "Membership Type 4"
                // },
                // {
                //     id: 5,
                //     name: "Membership Type 5"
                // },
            ],
            revenueTypeList: [
                // {
                //     id: 1,
                //     name: "Type Of Revenue 1"
                // },
                // {
                //     id: 2,
                //     name: "Type Of Revenue 2"
                // },
                // {
                //     id: 3,
                //     name: "Type Of Revenue 3"
                // },
                // {
                //     id: 4,
                //     name: "Type Of Revenue 4"
                // },
                // {
                //     id: 5,
                //     name: "Type Of Revenue 5"
                // },
            ],
            transactionTypeList: [
                // {
                //     id: 1,
                //     name: "Transaction Type 1"
                // },
                // {
                //     id: 2,
                //     name: "Transaction Type 2"
                // },
                // {
                //     id: 3,
                //     name: "Transaction Type 3"
                // },
                // {
                //     id: 4,
                //     name: "Transaction Type 4"
                // },
                // {
                //     id: 5,
                //     name: "Transaction Type 5"
                // },
            ],
            membershipTenureList: [
                // {
                //     id: 1,
                //     name: "Memberaship Tenure 1"
                // },
                // {
                //     id: 2,
                //     name: "Memberaship Tenure 2"
                // },
                // {
                //     id: 3,
                //     name: "Memberaship Tenure 3"
                // },
                // {
                //     id: 4,
                //     name: "Memberaship Tenure 4"
                // },
                // {
                //     id: 5,
                //     name: "Memberaship Tenure 5"
                // },
            ],
            designationList: [],
            statusList: [],
            corporateCreditTerms: [],
            taskListDetail: {
                taskId: 0,
                corporateMemberId: 0,
                companyName: "",
                companyUEN: "",
                coCompanyName: "",
                incorporationDate: "",
                firstName: "",
                lastName: "",
                designation: "",
                email: "",
                contactNo: "",
                billingCategoryId: 0,
                updatedBy: 1,
                subscriptionStartDate: "",
                subscriptionEndDate: "",
                startMembershipOn: "",
                selectedDesignation: [],
                lastUpdatedDate: moment(new Date()).format("YYYY-MM-DD"),
                membershipTypeId: 0,
                membershipTypeName: "",
                companyAnnualSalesTurnoverId: 0,
                transactionTypeId: 0,
                isQuotation: false,
                membershipTenureId: 0,
                sendQuotation: false,
                commentsToMember: "",
                internalNotes: "",
                additionalPromotion: "",
                comments: "",
                invoiceInternalNotes: "",
                isAppliedForRenewal: false,
                companyTypeId: 0,
                businessRegistrationNo: "",
                submittedOn: "",
                submittedBy: "",
                sendForApprovalOn: "",
                sendForApprovalBy: "",
                approvedOn: "",
                approvedBy: "",
                isApprovalRequired: false,
                isSendForApproval: false,
                isReject: false,
                isApproved: false,
                submittedById: 0,
                sendForApprovalById: 0,
                approvedById: 0,
                rejectedById: 0,
                rejectedBy: '',
                rejectedOn: '',
                quotationDate: '',
                membershipTermId: 1,
                dueDate: "",
                totalAmount: "",

            },
            setSendApprove: false,
            setRejectModal: false,
            setPaymentLink: false,
            sendQuotation: false,
            sendEscalation: false,
            isSendQuotation: false,
            // Send Escalate Details
            sendEscalate: {
                firstName: "",
                lastName: "",
                companyName: "",
                companyUEN: "",
                email: "",
                contactNo: "",
                internalNotes: "",
                additionalPromotion: ","
            },

            //   All Lodaer State
            isLoadingPayment: false,
            isLoadingQuotation: false,
            isLoadingInvoice: false,
            isLoadingApprove: false,
            isEscalateLodaing: false,

            totalAmount: "",


            //  Pricing Tables
            basePriceTable: [],
            allowableDiscountTable: [],
            percentageDiscountTable: [
                // {
                //     row: 1, year: 1, entranceFee: 15, annualFee: 15, tableName: 'PercentageDiscountTable', year: 1
                // },
                // {
                //     row: 2, year: 2, entranceFee: 10, annualFee: 10, tableName: 'PercentageDiscountTable', year: 2
                // },
                // {
                //     row: 3, year: 3, entranceFee: 5, annualFee: 5, tableName: 'PercentageDiscountTable', year: 3
                // },
                // {
                //     row: 4, year: 4, entranceFee: 3, annualFee: 3, tableName: 'PercentageDiscountTable', year: 4
                // },
                // {
                //     row: 5, year: 5, entranceFee: 0, annualFee: 0, tableName: 'PercentageDiscountTable', year: 5
                // },
            ],
            companyUENValidationRule: [
                {
                    type: "require",
                    message: CommonValidationMessages.FieldRequired.replace("{0}", "company UEN"),
                },
                {
                    type: "isCompanyUEN",
                    message: "Please enter valid company UEN"
                },
            ],
            businessRegistrationNoValidationRule: [
                {
                    type: "require",
                    message: CommonValidationMessages.FieldRequired.replace("{0}", "business registration number"),
                },
            ],
            membershipTenureValidationRule: [
                {
                    type: "require",
                    message: CommonValidationMessages.SelectRequired.replace("{0}", "membership tenure"),
                }
            ],
            systemCalculatedTable: [],
            disablePaymentButton: 0,
            validStateTaskListDetails: {
                isValid: true,
                error: {},
            },
            userId: 1,
            pic1UserId: 0,
            pic2UserId: 0,
            isReadyForSendPaymentLink: false
        }
    }

    componentDidMount() {
        let cookies = getAuthProps();
        this.setState({ userId: cookies.adminUserId });
        this.getAllTaskList();

        this.getAllDropdownsForMembershipTaskList();
    }

    // Get All Dropdown Method
    getAllDropdownsForMembershipTaskList = () => {
        this.membershipTaskListServices.getAllDropdownsForMembershipTaskList().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let membershipArr = response.responseItem.responseContent.filter(
                    (drp) => drp.dropdownName === "MembershipType"
                );
                membershipArr = membershipArr.filter(s => s.id === 1 || s.id === 2);
                let transactionArr = response.responseItem.responseContent.filter(
                    (drp) => drp.dropdownName === "TransactionType"
                );
                let billingCategoryArr = response.responseItem.responseContent.filter(
                    (drp) => drp.dropdownName === "BillingCategory"
                );
                let membershipRevenueArr = response.responseItem.responseContent.filter(
                    (drp) => drp.dropdownName === "MembershipRevenue"
                );
                let sourceArr = response.responseItem.responseContent.filter(
                    (drp) => drp.dropdownName === "CorporateProspectSource"
                );
                let userArr = response.responseItem.responseContent.filter(
                    (drp) => drp.dropdownName === "Users"
                );
                let statusName = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "StatusName");

                statusName = statusName.filter(s => s.id === 2 || s.id === 13 || s.id === 12 || s.id === 14 || s.id === 15);

                let designation = response.responseItem.responseContent.filter(drp => drp.dropdownName === 'Designation');
                designation.map(function (item) { delete item['dropdownName']; });
                designation = designation.map(p => ({
                    value: p.id, label: p.name
                }));


                let corporateCreditTerms = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "CorporateCreditTerms");

                membershipArr.map(function (item) {
                    delete item["dropdownName"];
                });
                transactionArr.map(function (item) {
                    delete item["dropdownName"];
                });
                billingCategoryArr.map(function (item) {
                    delete item["dropdownName"];
                });
                membershipRevenueArr.map(function (item) {
                    delete item["dropdownName"];
                });
                sourceArr.map(function (item) {
                    delete item["dropdownName"];
                });
                userArr.map(function (item) {
                    delete item["dropdownName"];
                });
                // statusName.map(function (item) {
                //     delete item["StatusName"]
                // });
                this.setState({
                    membershipTypeList: membershipArr,
                    transactionTypeList: transactionArr,
                    billingCategoryList: billingCategoryArr,
                    revenueTypeList: membershipRevenueArr,
                    sourceList: sourceArr,
                    usersList: userArr,
                    statusList: statusName,
                    designationList: designation,
                    corporateCreditTerms: corporateCreditTerms
                },
                    () => this.getAllTaskList()
                );
            }
            else {
                this.swalServices.Error(response.message);
                this.getAllTaskList();
            }
            // this.getMembershipPeriods()

            this.GetMembershipPeriods2(0, '')

            this.setState({ isLoading: false });
        });
    };

    // API Method Declaration Section Start
    getAllTaskList = (pagination = this.state.pagination) => {
        this.state.pagination.statusId = this.state.pagination.statusId
        this.setState({ isLoading: true });
        this.membershipTaskListServices.getAllTaskList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let details = response.responseItem.responseContent.itemList;
                details.map((key, index) => {
                    key.registrationDate = moment(key.registrationDate).format(
                        "DD MMM YYYY"
                    );

                    //     //   if (key.source == null || key.source == "") {
                    //     //     key.source = "-";
                    //     //   }
                    //     //   if (key.Email == null || key.Email == "") {
                    //     //     key.Email = "-";
                    //     //   }
                    //     //   if (key.FullName == null || key.FullName == "") {
                    //     //     key.FullName = "-";
                    //     //   }
                    //     //   if (key.CompanyName == null || key.CompanyName == "") {
                    //     //     key.CompanyName = "-";
                    //     //   }
                    //     //   if (key.CompanyUEN == null || key.CompanyUEN == "") {
                    //     //     key.CompanyUEN = "-";
                    //     //   }
                    //     //   if (key.registrationDate == null || key.registrationDate == "") {
                    //     //     key.registrationDate = "-";
                    //     //   }

                });


                this.setState({
                    membershipTaskList: response.responseItem.responseContent.itemList,
                    totalResultes: response.responseItem.responseContent.totalCount,
                }
                );
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    };

    // Get Membership Task List By Company Id
    getTaskByCompanyId = (corporateMemberId) => {
        this.membershipTaskListServices.getTaskByCompanyId(corporateMemberId).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {

                // response.responseItem.responseContent.transactionTypeId =
                //     response.responseItem.responseContent.transactionTypeId === null
                //         ? 1
                //         : response.responseItem.responseContent.transactionTypeId;
                let designation = [];
                if (response.responseItem.responseContent.designation !== null && response.responseItem.responseContent.designation !== "") {
                    let array = response.responseItem.responseContent.designation.split(',');
                    array.forEach(topic => {
                        let found = this.state.designationList.find(deg => deg.label === topic);
                        if (found) {
                            designation.push(found);
                        }
                        //designation.push(this.state.designationList.find(deg => deg.label === topic));
                    });
                }

                let resp = response.responseItem.responseContent;
                let data = this.state.taskListDetail;
                data.taskId = resp.taskId;
                data.companyName = resp.companyName;
                data.companyUEN = resp.companyUEN;
                data.corporateMemberId = resp.corporateMemberId;
                data.incorporationDate = resp.incorporationDate;
                data.firstName = resp.firstName;
                data.lastName = resp.lastName;
                data.designation = resp.designation;
                // data.selectedDesignation = resp.designation;
                data.email = resp.email;
                data.contactNo = resp.contactNo;
                data.billingCategoryId = resp.billingCategoryId;
                // data.updatedBy=resp. 
                data.subscriptionStartDate = resp.subscriptionStartDate;
                data.subscriptionEndDate = resp.subscriptionEndDate;
                // data.lastUpdatedDate=resp. 
                data.membershipTypeId = resp.membershipTypeId;
                data.companyAnnualSalesTurnoverId = resp.companyAnnualSalesTurnoverId;
                data.transactionTypeId = resp.transactionTypeId ? resp.transactionTypeId : 1;
                data.isQuotation = resp.quotationNeeded;
                data.membershipTenureId = resp.membershipTenureId;
                // data.sendQuotation= resp.
                data.commentsToMember = resp.commentsToMember;
                // data.internalNotes = resp.internalNotes;
                data.additionalPromotion = resp.additionalPromotion;
                data.comments = resp.comments;
                data.coCompanyName = resp.coCompanyName;
                // data.invoiceInternalNotes= resp.
                data.companyTypeId = resp.companyTypeId;
                data.businessRegistrationNo = resp.businessRegistrationNo;
                data.startMembershipOn = resp.startMembershipOn;
                data.isAppliedForRenewal = resp.isAppliedForRenewal;
                data.isQuotation = resp.isQuotation;
                data.submittedOn = resp.submittedOn;
                data.submittedBy = resp.submittedBy;
                data.sendForApprovalOn = resp.sendForApprovalOn;
                data.sendForApprovalBy = resp.sendForApprovalBy;
                data.approvedOn = resp.approvedOn;
                data.approvedBy = resp.approvedBy;
                data.isApprovalRequired = resp.isApprovalRequired;
                data.isSendForApproval = resp.isSendForApproval;

                data.isApproved = resp.isApproved;
                data.sendQuotation = resp.isRequireQuotation;
                // data.isQuotation = resp.isRequireQuotation;

                data.submittedById = resp.submittedById;
                data.sendForApprovalById = resp.sendForApprovalById;
                data.approvedById = resp.approvedById;
                data.invoiceInternalNotes = resp.internalNotes;
                data.isReject = resp.isRejected;
                data.rejectedById = resp.rejectedById;
                data.rejectedBy = resp.rejectedBy;
                data.rejectedOn = resp.rejectedOn;
                data.startMembershipOn = resp.quotationDate;
                data.quotationDate = resp.quotationDate;
                data.membershipTermId = resp.membershipTermId === null ? 1 : resp.membershipTermId;
                data.commentsToMember = resp.quotationCommentToMember;
                data.internalNotes = resp.quotationInternalNotes;

                // data.submittedBy = `Application Submitted on  ${moment(data.submittedOn).format(
                //     "MMMM Do YYYY, h:mm a"
                //   )} by ${data.submittedBy}`,

                data.sendForApprovalBy = data.sendForApprovalBy !== null && data.sendForApprovalBy != '' ? `Approval Request submitted on  ${moment(data.sendForApprovalOn).format(
                    "MMMM Do YYYY, h:mm a"
                )} by ${data.sendForApprovalBy}` : '';

                data.approvedBy = data.approvedBy !== null && data.approvedBy != '' ? `Approved on  ${moment(data.approvedOn).format(
                    "MMMM Do YYYY, h:mm a"
                )} by ${data.approvedBy}` : '';

                data.rejectedBy = data.rejectedBy !== null && data.rejectedBy != '' ? `Rejected on  ${moment(data.rejectedOn).format(
                    "MMMM Do YYYY, h:mm a"
                )} by ${data.rejectedBy}` : '';

                this.setState({ taskListDetail: data }, () => {
                    if (data.quotationDate !== null && data.quotationDate != undefined) {
                        this.GetMembershipPeriods2(0, data.quotationDate);
                    }
                    this.getExistingDiscountTableByTaskId(resp.taskId)
                });

                this.setState({ pic1UserId: data.sendForApprovalById })
                this.setState({ pic2UserIdUserId: data.approvedById })
                if (data.sendForApprovalById === this.state.userId) {
                    this.setState({ isReadyForSendPaymentLink: false })


                } else {
                    this.setState({ isReadyForSendPaymentLink: true })
                }


                this.getAllPriceTables();

                // this.setState(
                //   { taskListDetail: response.responseItem.responseContent },
                //   () => {
                //     let data = { ...this.state.taskListDetail };
                //     //data.selectedDesignation = designation;
                //     data.startMembership = data.startMembershipOn ? true : false;
                //     this.setState({ taskListDetail: data });
                //     // this.getPriceTabelForTaskListProcess();
                //     //this.getCorporatePriceTabelForTaskListProcess();
                //   },
                //   () => this.setState({ validState: { isValid: true, error: {} } })
                // );          

                // this.setState({ setAddeditModalOpen: true });
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    };

    // Get All Pricing Table Method
    getAllPriceTables = () => {
        let request = {
            CorporateMemberId: this.state.taskListDetail.corporateMemberId,
            MembershipTypeId: this.state.taskListDetail.membershipTypeId,
            CompanyAnnualSalesTurnoverId: this.state.taskListDetail.companyAnnualSalesTurnoverId ? this.state.taskListDetail.companyAnnualSalesTurnoverId : 1,
            MembershipTenureId: 1,
            IncorporationDate: this.state.taskListDetail.incorporationDate,
            MembershipCategoryId: this.state.taskListDetail.billingCategoryId ? this.state.taskListDetail.billingCategoryId : 1,
            TransactionTypeId: this.state.taskListDetail.transactionTypeId,
            // isAppliedForRenewal: this.state.taskListDetail.isAppliedForRenewal
        };
        this.membershipTaskListServices.getAllPriceTables(request).then(
            (response) => {

                if (response.statusCode === 200 && response.responseItem != null) {
                    var basePriceTable = response.responseItem.responseContent.filter(
                        (drp) => drp.tableName === "BasePriceTable"
                    );
                    var allowableDiscountTable =
                        response.responseItem.responseContent.filter(
                            (drp) => drp.tableName === "AllowableDiscountTable"
                        );
                    var percentageDiscountTable =
                        response.responseItem.responseContent.filter(
                            (drp) => drp.tableName === "PercentageDiscountTable"
                        );
                    var systemCalculatedTable =
                        response.responseItem.responseContent.filter(
                            (drp) => drp.tableName === "SystemCalculatedTable"
                        );

                    basePriceTable.forEach((element, key) => {
                        basePriceTable[key].status = "Ok";
                        if (this.state.taskListDetail.isAppliedForRenewal === true) {
                            basePriceTable[key].entranceFee = 0;
                        }
                    });
                    if (allowableDiscountTable.length === 0) {
                        allowableDiscountTable.forEach((element, key) => {
                            allowableDiscountTable.push(element);
                        });
                    }
                    let diff = "";
                    let yearDiff = 0;
                    let yearDiffForRenew = 0;
                    if (this.state.taskListDetail.membershipTypeId === 3) {
                        // if (!request.subscriptionEndDate && !request.subscriptionStartDate) {
                        //   let todayDate = new Date();
                        //   let inCorDate = new Date(this.state.taskListDetail.incorporationDate);
                        //   let Difference_In_Time = todayDate.getTime() - inCorDate.getTime();
                        //   let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                        //   let Difference_In_Years = Difference_In_Days / 365;
                        //   diff = Math.floor(Difference_In_Years);
                        // }
                        let newtenureArr = [];
                        if (!isNaN(Date.parse(this.state.taskListDetail.incorporationDate))) {
                            let sDate = new Date(this.state.taskListDetail.incorporationDate);
                            let eDate = new Date();
                            yearDiff = eDate.getFullYear() - sDate.getFullYear();
                            yearDiff = yearDiff === 0 ? 0 : yearDiff;

                            // for (let i = 1; i <= 5 - yearDiff; i++) {
                            //   let tenure = {
                            //     id: i,
                            //     name: `${i} Year`
                            //   }
                            //   newtenureArr.push(tenure);
                            // }
                            // this.setState({ tenureList: newtenureArr });

                            if (this.state.taskListDetail.isAppliedForRenewal === true && yearDiff < 5) {
                                let eDate = new Date(this.state.taskListDetail.subscriptionEndDate);
                                let currentDate = new Date();
                                yearDiffForRenew = eDate.getFullYear() - currentDate.getFullYear();
                                for (let i = 1; i <= 5 - yearDiff - yearDiffForRenew; i++) {
                                    let tenure = {
                                        id: i,
                                        name: `${i} Year`
                                    }
                                    newtenureArr.push(tenure);
                                }
                                this.setState({ tenureList: newtenureArr });
                            } else {
                                for (let i = 1; i <= 5 - yearDiff; i++) {
                                    let tenure = {
                                        id: i,
                                        name: `${i} Year`
                                    }
                                    newtenureArr.push(tenure);
                                }
                                this.setState({ tenureList: newtenureArr });
                            }
                        }
                    } else {
                        this.setState({ tenureList: this.state.storeBasetenureList });
                    }
                    if (percentageDiscountTable.length === 0) {
                        basePriceTable.forEach((element, key) => {
                            var obj = {
                                row: key + 11,
                                year: key + 1,
                                entranceFee: 0,
                                annualFee: 0,
                                includedChapter: this.state.taskListDetail.membershipTypeId === 5 ? 0 : 1,
                                newChapterPrice: 0,
                                tableName: "PercentageDiscountTable",
                                status: "Ok",
                                isDeleted: true
                            };
                            if (key + 1 > yearDiff + yearDiffForRenew) {
                                obj.isDeleted = false;
                            } else {
                                obj.isDeleted = true;
                            }
                            percentageDiscountTable.push(obj);
                        });
                    }
                    if (systemCalculatedTable.length === 0) {
                        basePriceTable.forEach((element, key) => {
                            var obj = {
                                row: key + 16,
                                year: key + 1,
                                entranceFee: 0,
                                annualFee: 0,
                                includedChapter: 0,
                                newChapterPrice: 0,
                                tableName: "SystemCalculatedTable",
                                isDeleted: true
                            };
                            if (key + 1 > yearDiff + yearDiffForRenew) {
                                obj.isDeleted = false;
                            } else {
                                obj.isDeleted = true;
                            }
                            systemCalculatedTable.push(obj);
                        });
                    }
                    var forPayment = percentageDiscountTable.filter(
                        (arr) =>
                            arr.status === "Required Approval" || arr.status === "Rejected"
                    );
                    if (forPayment.length > 0) {
                        this.setState({ disablePaymentButton: 0 });
                    } else {
                        this.setState({ disablePaymentButton: 0 });
                    }
                    this.setState(
                        {
                            basePriceTable: basePriceTable,
                            allowableDiscountTable: allowableDiscountTable,
                            percentageDiscountTable: percentageDiscountTable,
                            systemCalculatedTable: systemCalculatedTable,
                        },
                        () => {
                            if (
                                this.state.taskListDetail.taskId &&
                                this.state.taskListDetail.taskId > 0
                            ) {
                                this.getExistingDiscountTableByTaskId(
                                    this.state.taskListDetail.taskId
                                );
                            } else {
                                this.getCalculatedFinalPrice();
                            }
                            //this.getCalculatedFinalPrice(allowableDiscountTable);
                        }
                    );
                } else {
                    this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
            }
        );
    };

    // Add Edit Membership Task And Send For Approval Method
    addEditMembershipTask = (isRejected) => {
        // let isValidInput = this.isValidateAllFields();
        // if (isValidInput) {   
        var pricingList = [];
        var request = { ...this.state.taskListDetail, ...pricingList };
        request.taskId = this.state.selectedTaskId;
        // var forPayment = this.state.percentageDiscountTable.filter(
        //   (arr) => arr.status === "Required Approval" || arr.status === "Rejected"
        // );
        request.IsApprovalRequired = true;
        request.IsPaymentLinkSent = false;
        // if (forPayment.length > 0) {
        //   request.IsApprovalRequired = true;
        // }
        // else {
        //   request.IsApprovalRequired = false;
        // }
        request.pricingList = this.state.systemCalculatedTable.concat(
            this.state.percentageDiscountTable,
            this.state.basePriceTable
        );
        // request.designation = this.state.taskListDetail.designation;
        // if (request.selectedDesignation && request.selectedDesignation.length > 0) {
        //     var arr1 = request.selectedDesignation.map(function (item) {
        //         delete item.bad;
        //         return item.label;
        //     });
        //     request.designation = arr1.join([',']);
        // } else {
        //     request.designation = "";
        // }
        if (request.membershipTypeId != null && request.membershipTypeId > 0) {
            let found = this.state.membershipTypeList.find(x => x.id === request.membershipTypeId);
            if (found) {
                request.membershipTypeName = found.name;
            } else {
                request.membershipTypeName = "";
            }
        }
        request.updatedBy = this.state.userId;
        request.additionalPromotion = request.additionalPromotion;
        request.comments = request.comments;
        request.invoiceInternalNotes = request.invoiceInternalNotes;
        request.isReject = isRejected;

        // this.swalServices
        //     .Confirm(
        //         CommonSuccessMessages.Confirm_Payment.replace("{0}", "Payment Link"),
        //         isRejected === true ?

        //             CommonSuccessMessages.Confirm_Reject.replace("{0}", "member")
        //             :
        //             CommonSuccessMessages.Confirm_Approve.replace("{0}", "member"),
        //         CommonSuccessMessages.Yes_Text,
        //         CommonSuccessMessages.No_Text
        //     )
        //     .then((response) => {
        //         if (response) {  
        this.setState({ isLoadingApprove: true });
        this.membershipTaskListServices.addEditMembershipTaskAndSendForApproval(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                if (isRejected) {
                    this.setState({ setRejectModal: true }, () => {
                        this.setState({ setAddeditModalOpen: false });
                        this.getAllTaskList();
                    });
                }
                else {
                    this.setState({ setSendApprove: true }, () => {
                        this.setState({ setAddeditModalOpen: false });
                        this.getAllTaskList();
                    });
                }
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoadingApprove: false });
        });
        // }
        // }).finally(() => {
        //     this.setState({ isLoadingApprove: false });
        // });
        // }
    };

    // Get Existing Discount Table Method
    getExistingDiscountTableByTaskId = (taskId) => {
        this.membershipTaskListServices.getExistingDiscountTableByTaskId(taskId).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                var percentageDiscount = response.responseItem.responseContent.filter(
                    (drp) => drp.tableName = "PercentageDiscountTable"
                );
                // if (percentageDiscount.length === 0) {
                //     percentageDiscount = [];
                //     basePrice.forEach((element, key) => {
                //         var obj = {
                //             row: key + 11,
                //             year: key + 1,
                //             entranceFee: 0,
                //             annualFee: 0,
                //             tableName: "PercentageDiscountTable",
                //             status: "Ok",
                //         };
                //         percentageDiscount.push(obj);
                //     });
                // }
                if (response.responseItem.responseContent.length === 5) {
                    this.setState({
                        percentageDiscountTable: percentageDiscount
                    }
                        , () => { this.getCalculatedFinalPrice(); }
                    )
                } else {
                    this.getCalculatedFinalPrice();
                }
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    getCalculatedFinalPrice = () => {
        this.setState({ isLoading: true });
        let request = [];
        request = this.state.basePriceTable.concat(
            this.state.percentageDiscountTable
        );
        this.membershipTaskListServices.getCalculatedFinalPrice(request).then((response) => {
            if (response.statusCode = 200 && response.responseItem != null) {
                this.setState(
                    {
                        systemCalculatedTable: response.responseItem.responseContent,
                    },
                    () => {
                        let forPayment = this.state.percentageDiscountTable.filter(
                            (arr) =>
                                arr.status === "Required Approval" || arr.status === "Rejected"
                        );
                        if (forPayment.length > 0) {
                            this.setState({ disablePaymentButton: 0 });
                        } else {
                            this.setState({ disablePaymentButton: 0 });
                        }
                        this.setAllPricingTables();
                    }
                );
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    };

    setAllPricingTables = () => {
        let setBasePriceTable = [...this.state.basePriceTable];
        let setAllowableDiscountTable = [...this.state.allowableDiscountTable];
        let setPercentageDiscountTable = [...this.state.percentageDiscountTable];
        let setSystemCalculatedTable = [...this.state.systemCalculatedTable];

        let diff = "";
        let yearDiff = 0;
        let yearDiffForRenew = 0;
        if (this.state.taskListDetail.membershipTypeId === 3) {
            // if (!this.state.taskListDetail.subscriptionEndDate && !this.state.taskListDetail.subscriptionStartDate) {
            //   let todayDate = new Date();
            //   let inCorDate = new Date(this.state.taskListDetail.incorporationDate);
            //   let Difference_In_Time = todayDate.getTime() - inCorDate.getTime();
            //   let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            //   let Difference_In_Years = Difference_In_Days / 365;
            //   diff = Math.floor(Difference_In_Years);
            // }
            if (!isNaN(Date.parse(this.state.taskListDetail.incorporationDate))) {
                let sDate = new Date(this.state.taskListDetail.incorporationDate);
                let eDate = new Date();
                yearDiff = eDate.getFullYear() - sDate.getFullYear();
                yearDiff = yearDiff === 0 ? 0 : yearDiff;
            }
            if (this.state.taskListDetail.isAppliedForRenewal === true && yearDiff < 5) {
                let eDate = new Date(this.state.taskListDetail.subscriptionEndDate);
                let currentDate = new Date();
                yearDiffForRenew = eDate.getFullYear() - currentDate.getFullYear();
            }
        }
        this.setState({
            basePriceTable: setBasePriceTable, allowableDiscountTable: setAllowableDiscountTable,
            percentageDiscountTable: setPercentageDiscountTable, systemCalculatedTable: setSystemCalculatedTable
        });
    }
    // API Method Declaration Section End

    // Validation Method Declaration Section
    validateField = (key) => {
        let validationRule = { ...TaskListDetailsValidationRules };
        if (this.state.taskListDetail.companyTypeId === 1) {
            validationRule.companyUEN = this.state.companyUENValidationRule;
        }
        else if (this.state.taskListDetail.companyTypeId === 2) {
            validationRule.businessRegistrationNo = this.state.businessRegistrationNoValidationRule;
        }
        const returnValidState = validate(
            key,
            this.state.taskListDetail,
            validationRule,
            this.state.validStateTaskListDetails);
        this.setState({ validStateTaskListDetails: returnValidState });
    }
    isValidateAllFields = () => {
        let validationRule = { ...TaskListDetailsValidationRules };
        if (this.state.taskListDetail.companyTypeId === 1) {
            validationRule.companyUEN = this.state.companyUENValidationRule;
        }
        else if (this.state.taskListDetail.companyTypeId === 2) {
            validationRule.businessRegistrationNo = this.state.businessRegistrationNoValidationRule;
        }
        if (this.state.isSendQuotation === true) {
            validationRule.membershipTenureId = this.state.membershipTenureValidationRule;
        }
        const newValidState = isValidForm(
            this.state.taskListDetail,
            validationRule,
            this.state.validStateTaskListDetails);
        this.setState({ validStateTaskListDetails: newValidState });
        return newValidState.isValid;
    };

    // Internal handling Method Declaration Section

    // Set Pagination For Grid Method
    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getAllTaskList(newPagination);
    };

    // Search By Name Filter Method
    searchByName = (value) => {
        let detail = this.state.pagination;
        detail.searchText = "";
        this.setState({ pagination: { ...detail } });
        if (value.length > 2) {
            detail.pageNo = 1;
            detail.pageSize = 10;
            detail.searchText = value;
            this.setState({ pagination: { ...detail } });
        }
        this.getAllTaskList();
    };


    // All Dropdown Handle Method
    setFilterParameters = (id, drpIdentity) => {
        let detail1 = this.state.pagination;
        let detail2 = this.state.taskListDetail;

        if (drpIdentity === "Filter_By_Source") {
            detail1["filterBySource"] = id;
            //this.validateField("resourceTypeId");
        }
        else if (drpIdentity === "Billing_Category") {
            detail2["billingCategoryId"] = id;
        }
        else if (drpIdentity === "Updated_By") {
            detail2["updatedBy"] = id;
        }
        else if (drpIdentity === "Membership_Type") {
            detail2["membershipTypeId"] = id;
            this.validateField("membershipTypeId");
        }
        else if (drpIdentity === "Revenue_Type") {
            detail2["companyAnnualSalesTurnoverId"] = id;
            this.validateField("companyAnnualSalesTurnoverId");
        }
        else if (drpIdentity === "Transaction_Type") {
            detail2["transactionTypeId"] = id;
            this.validateField("transactionTypeId");
        }
        else if (drpIdentity === "Membership_Tenure") {
            detail2["membershipTenureId"] = id;
            // this.validateField("membershipTenureId");
        }
        else if (drpIdentity === "Status_Name") {
            detail1["statusId"] = id;
        }
        else if (drpIdentity === "MembershipTerm") {
            detail2["membershipTermId"] = id;
            // let termday = 0;
            // if (id == 1) {
            //     termday = 7
            // }
            // if (id == 2) {
            //     termday = 14
            // }
            // if (id == 3) {
            //     termday = 30
            // }
            // if (id == 4) {
            //     termday = 60
            // }
            // let newDate = new Date().toDateString();
            // newDate.setDate(newDate + termday);
            // var dueDate = new Date(newDate + termday);
        }
        this.setState({
            pagination: { ...detail1 },
            taskListDetail: { ...detail2 }
        }, () => {
            this.getAllPriceTables();
        });
        this.getAllTaskList();
    };

    // Handle All Type Input Method
    handleChange(event, identity) {
        const value = event.target.value;
        const name = event.target.name;

        let detail1 = this.state.pagination;
        let detail2 = this.state.taskListDetail;

        if (identity === "Filter_By_Date") {
            let fulldate = (detail1[name] = value === "" ? null : value);
            let date = new Date(fulldate); //1/1/1753 12:00:00 AM and 12/31/9999 11:59:59 PM
            let startLimit = new Date("1/1/1753 12:00:00 AM");
            let endLimit = new Date("12/31/9999 11:59:59 PM");
            detail1[name] = value;
            this.setState({ pagination: { ...detail1 } });
            this.setState({ pagination: { ...detail1 } }, () => {
                if (startLimit < date && date < endLimit) {
                    this.getAllTaskList();
                } else if (fulldate === null) {
                    this.getAllTaskList();
                }
            });
        }
        else if (identity === "Task_List_Detail") {
            detail2[name] = value;
            if (name === "startMembershipOn") {
                //value.date
                detail2.quotationDate = new Date(value);
                this.setState({ taskListDetail: detail2 }, this.GetMembershipPeriods2(0, detail2.quotationDate));

            } else {
                this.setState({ taskListDetail: detail2 });
            }
        }

        // Handle Change In Checkbox and Radio
        else if (identity === "Task_List_Detail_CheckboxesRadio") {
            detail2[name] = !detail2[name];
            this.setState({ taskListDetail: detail2 });
        }
    }

    handleChangeInPriceTable(value, name, year, tableName) {
        var table = this.state.percentageDiscountTable;
        var allowable = this.state.allowableDiscountTable;
        var allowableValue = Number(allowable[year][name]);
        var approvalStatus = [];
        value = isNaN(value) ? 0 : Number(value);
        if (value > 100) {
            value = 100;
        }
        // if (tableName === "PercentageDiscountTable") {
        table[year][name] = value;
        if (table[year]["annualFee"] > allowable[year]["annualFee"]) {
            approvalStatus.push("Required Approval");
        } else {
            approvalStatus.push("Ok");
        }
        if (table[year]["entranceFee"] > allowable[year]["entranceFee"]) {
            approvalStatus.push("Required Approval");
        } else {
            approvalStatus.push("Ok");
        }
        if (approvalStatus.indexOf("Required Approval") > -1) {
            table[year]["status"] = "Required Approval";
        } else {
            table[year]["status"] = "Ok";
        }
        this.setState({ percentageDiscountTable: table }
            , () => this.getCalculatedFinalPrice()
        );
    }

    // actions = (element, index, value) => {
    //     return element !== "corporateMemberId" ? null : (
    //         <td className="whitespace-nowrap tble-td-txt break-all text-left">
    //             <button
    //                 className="flex items-center justify-center"
    //                 onClick={(event) => this.selectTask(event, value)
    //                 }
    //             >
    //                 <span className="text-[#8DA4B5] font-bold underline ml-5 2xl:text-[15px]">
    //                     View
    //                 </span>
    //             </button>
    //         </td >
    //     );
    // };

    selectTask = (event, value) => {
        // var id = this.state.taskListDetail[index].corporateMemberId;
        this.setState({ setAddeditModalOpen: true, selectedTaskId: value },
            this.getTaskByCompanyId(value),

        );

    }

    // Send Quotation to Member Method
    sendQuotationEmailToCorporateMember = () => {
        this.setState({ isSendQuotation: true });
        this.CheckIsValidStartupMember(false);
    };

    SendQuotationtToMember = () => {

        let isValidInput = this.isValidateAllFields();
        if (isValidInput) {
            this.setState({ isLoadingQuotation: true });
            let pricingList = [...this.state.systemCalculatedTable];
            let request = { ...this.state.taskListDetail };
            request.year = this.state.membershipTenureList.find(s => s.id == request.membershipTenureId).year;
            request.months = this.state.membershipTenureList.find(s => s.id == request.membershipTenureId).months;
            request.subscriptionStartDate = this.state.membershipTenureList.find(s => s.id == request.membershipTenureId).startDate;
            request.subscriptionEndDate = this.state.membershipTenureList.find(s => s.id == request.membershipTenureId).endDate;

            let priceObj = pricingList.find(
                (price) => price.year === request.year
            );
            //   request.year = priceObj.year;
            request.entranceFee = priceObj.entranceFee;



            let yearDifference = '';
            let validationYear = "";
            let diff = "";
            if (request.membershipTypeId === 3) {
                request.annualFee = 0;
                if (!isNaN(Date.parse(request.incorporationDate))) {
                    let sDate = new Date(request.incorporationDate);
                    let eDate = new Date();
                    yearDifference = eDate.getFullYear() - sDate.getFullYear();
                    yearDifference = yearDifference === 0 ? 1 : yearDifference;

                    if (request.isAppliedForRenewal === true && yearDifference < 5) {
                        let eDate = new Date(this.state.taskListDetail.subscriptionEndDate);
                        let currentDate = new Date();
                        let yearDiffForRenew = eDate.getFullYear() - currentDate.getFullYear();

                        pricingList.forEach((priceObj, PriceIndex) => {
                            if (PriceIndex > (yearDifference - 1) + yearDiffForRenew && priceObj.year <= yearDifference + request.year + yearDiffForRenew) {
                                request.annualFee = request.annualFee + priceObj.annualFee;
                            }
                        });
                    }
                    else {
                        pricingList.forEach((priceObj, PriceIndex) => {
                            if (PriceIndex > yearDifference - 1 && priceObj.year <= yearDifference + request.year) {
                                request.annualFee = request.annualFee + priceObj.annualFee;
                            }
                        });
                    }
                }
                else {
                    pricingList.forEach((priceObj, PriceIndex) => {
                        if (priceObj.year <= request.membershipTenureId) {
                            request.annualFee = request.annualFee + priceObj.annualFee;
                        }
                    });
                }
            } else {
                request.annualFee = priceObj.annualFee;
            }
            request.tableName = priceObj.tableName;
            request.firstName = this.state.taskListDetail.firstName;
            request.lastName = this.state.taskListDetail.lastName;
            request.companyName = this.state.taskListDetail.companyName;
            request.email = this.state.taskListDetail.email;
            request.commentsToMember = this.state.taskListDetail.commentsToMember;
            request.additionalPromotion = this.state.taskListDetail.additionalPromotion;
            request.internalNotes = this.state.taskListDetail.internalNotes;
            request.coCompanyName = this.state.taskListDetail.coCompanyName;

            request.startMembershipOn = this.state.taskListDetail.startMembershipOn ? this.state.startMembershipOn : null;
            request.corporateMemberId = this.state.taskListDetail.corporateMemberId;
            request.taskId = this.state.taskListDetail.taskId;

            request.pricingList = this.state.systemCalculatedTable.concat(
                this.state.percentageDiscountTable,
                this.state.basePriceTable
            );
            // if (request.selectedDesignation && request.selectedDesignation.length > 0) {
            //     var arr1 = request.selectedDesignation.map(function (item) {
            //         delete item.bad;
            //         return item.label;
            //     });
            //     request.designation = arr1.join([',']);
            // } else {
            //     request.designation = "";
            // }
            if (request.year > 0) {
                this.membershipTaskListServices.sendQuotationEmailToCorporateMember(request).then((response) => {
                    if (response.statusCode === 200 && response.responseItem != null) {
                        let reset = this.state.taskListDetail;
                        this.state.totalAmount = response.responseItem.responseContent.totalPrice;
                        this.setState({ taskListDetail: reset });
                        this.setState({ sendQuotation: true }, () =>
                            this.setState({ setAddeditModalOpen: false })
                        );
                        this.setState({ totalAmount: this.state.totalAmount });

                    } else {
                        this.swalServices.Error(response.message);
                    }
                    this.setState({ isLoadingQuotation: false, isSendQuotation: false });
                });
            } else {
                this.swalServices.Error("Cant select membership years less than the incorporation date");
                this.setState({ isLoadingQuotation: false });
            }
        }
    }

    actions = (element, index, value) => {
        return element !== "corporateMemberId" ? null : (
          <td>
            <ActionContainer>
              <DropdownAction
                key={index}
                index={index}
                value={value}
                options={ this.state.actionDropdown}
                align="right"
                onOptionClick= {this.actionClick.bind(this)}
              />
            </ActionContainer>
          </td>
        );
      };

      actionClick = (index, value, option) => {
        if (value && value > 0 && option === "View") {
            this.selectTask(null,value);
        }
        if (value && value > 0 && option === "Delete") {
           let taskId =   this.state.membershipTaskList[index].taskId;
           this.deleteCorporateMemberFromTaskList(taskId);
        }
      };

    // Send Payment Link to Member
    SendPaymentLinkToMember = () => {
        let isValidInput = this.isValidateAllFields();
        if (isValidInput) {
            let pricingList = [];
            let request = { ...this.state.taskListDetail, ...pricingList };
            request.taskId = this.state.selectedTaskId;
            request.updatedAppId = 1;
            request.coCompanyName = "";
            // request.push(this.state.systemCalculatedTable.concat(
            //   this.state.percentageDiscountTable
            // ));
            // request.pricingList = [];
            request.pricingList = this.state.systemCalculatedTable.concat(
                this.state.percentageDiscountTable,
                this.state.basePriceTable
            );
            //request.months = this.state.membershipTenureList.find(s => s.id == request.membershipTenureId).months;
            request.totalAmount = this.state.totalAmount;
            // request.months = this.state.months;

            // request.pricingList.push(this.state.basePriceTable);
            // request.pricingList.push(this.state.percentageDiscountTable);
            // request.percentageDiscountTable = this.state.percentageDiscountTable;
            request.IsApprovalRequired = false;
            request.IsPaymentLinkSent = true;

            // if (request.selectedDesignation && request.selectedDesignation.length > 0) {
            //     let arr1 = request.selectedDesignation.map(function (item) {
            //         delete item.bad;
            //         return item.label;
            //     });
            //     request.designation = arr1.join([',']);
            // } else {
            //     request.designation = "";
            // }
            request.updatedBy = this.state.userId;
            this.setState({ isLoadingPayment: true });
            this.membershipTaskListServices.addEditMembershipTaskAndSendPaymentLink(request).then((response) => {
                if (
                    response.statusCode === 200 &&
                    response.responseItem != null
                ) {
                    // window.location.replace('https://web.sgtech-test.infixsofttech.com//membership/MembershipPayment/' + response.responseItem.responseContent.keyId)
                    // this.setState({ setAddeditModalOpen: false }, () =>
                    //   this.getAllTaskList()
                    // );
                    //this.swalServices.Success(
                    //  SuccessMessage.Update_Success.replace("{0}", "Task")
                    //);
                    // this.addEditMembershipTask()
                    this.setState({ setPaymentLink: true }, () =>
                        this.setState({ setAddeditModalOpen: false })
                    );
                } else {
                    this.swalServices.Error(response.message);
                }
                this.setState({ isLoadingPayment: false });
            });
        }
        else {
            this.swalServices.Error("Please fill all mandatory fields");
        }
    }

    // Send Payment Link on Button Click Method 
    addEditMembershipTaskAndSendPaymentLink = () => {
        this.swalServices
            .Confirm(
                CommonSuccessMessages.Confirm_Payment.replace("{0}", "Payment Link"),
                CommonSuccessMessages.Send_Payment_Link.replace("{0}", "Payment Link"),
                CommonSuccessMessages.Yes_Text,
                CommonSuccessMessages.No_Text
            )
            .then((response) => {
                let resp = response;
                if (resp === true) {
                    this.CheckIsValidStartupMember(true);
                }
            });
    };

    // Check Is Valid Start up Member or Not
    CheckIsValidStartupMember = (isPaymentLink = false) => {
        if (this.state.taskListDetail.membershipTypeId === 3) {
            let rules = { ...this.state.validationRule }
            let invoiceRules = { ...this.state.invoiceValidation }
            let newruleincorporationDate = [
                {
                    type: "require",
                    message: CommonValidationMessages.FieldRequired.replace(
                        "{0}",
                        "incorporation date"
                    ),
                },
            ];
            rules.incorporationDate = newruleincorporationDate;
            invoiceRules.incorporationDate = newruleincorporationDate;
            this.setState({ validationRule: rules, invoiceValidation: invoiceRules }, () => {
                if (!isNaN(Date.parse(this.state.taskListDetail.incorporationDate))) {
                    let sDate = new Date(this.state.taskListDetail.incorporationDate);
                    let eDate = new Date();
                    let yearDiff = eDate.getFullYear() - sDate.getFullYear();
                    yearDiff = yearDiff === 0 ? 1 : yearDiff;
                    if (this.state.taskListDetail.membershipTypeId === 3 && yearDiff > 4) {
                        this.setState({ isValidStartUpMember: false }, () => {
                            this.swalServices.Warning("Company’s incorporation date is greater than 5 years ago.");
                            if (isPaymentLink === true) {
                                this.SendPaymentLinkToMember();
                            } else {
                                this.SendQuotationtToMember();
                            }
                        });
                    } else {
                        if (isPaymentLink === true) {
                            this.setState({ isValidStartUpMember: true }, () => {
                                this.SendPaymentLinkToMember();
                            })
                        } else {
                            this.setState({ isValidStartUpMember: true }, () => {
                                this.SendQuotationtToMember();
                            })
                        }
                    }
                } else {
                    if (isPaymentLink === true) {
                        this.setState({ isValidStartUpMember: true }, () => {
                            this.SendPaymentLinkToMember();
                        })
                    } else {
                        this.setState({ isValidStartUpMember: true }, () => {
                            this.SendQuotationtToMember();
                        })
                    }
                }
            });
        } else {
            let valRule = { ...this.state.validationRule };
            let invoiceValRule = { ...this.state.invoiceValidation };
            if (valRule.incorporationDate) {
                delete valRule.incorporationDate;
            }
            if (invoiceValRule.incorporationDate) {
                delete invoiceValRule.incorporationDate;
            }
            this.setState({ validationRule: valRule, invoiceValidation: invoiceValRule });
            this.setState({ isValidStartUpMember: true }, () => {
                if (isPaymentLink === true) {
                    this.SendPaymentLinkToMember();
                } else {
                    this.SendQuotationtToMember();
                }
            })
        }
    }

    // Escalate the Application Method
    sendEscalateMailToMember = () => {
        let request = this.state.sendEscalate;
        request.firstName = this.state.taskListDetail.firstName;
        request.lastName = this.state.taskListDetail.lastName;
        request.companyName = this.state.taskListDetail.companyName;
        request.companyUEN = this.state.taskListDetail.companyUEN;
        request.email = this.state.taskListDetail.email;
        request.contactNo = this.state.taskListDetail.contactNo;
        request.internalNotes = this.state.taskListDetail.internalNotes;
        request.additionalPromotion = this.state.taskListDetail.additionalPromotion;
        this.setState({ isEscalateLodaing: true });
        this.membershipTaskListServices.sendEscalateMailToMember(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ sendEscalation: true }, () =>
                    this.setState({ setAddeditModalOpen: false })
                );
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isEscalateLodaing: false });
        });
    };

    quotationDownload = () => {
        let request = {}

        request.corporateMemberId = this.state.taskListDetail.corporateMemberId;

        this.setState({ isEscalateLodaing: true });
        this.membershipTaskListServices.downloadQuotation(request).then((response) => {
            if (response != null) {
                var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss")
                var filename = `Quotation_${formattedDate}`;
                var blob = new Blob([response.data])
                saveAs(blob, filename + ".pdf");
            }
            else {
                this.swalServices.Error(response.message);
            }

        });
    };

    navigateToViewCorporateMember = () => {
        let corporateMemberId = this.state.taskListDetail.corporateMemberId
        if (corporateMemberId && corporateMemberId > 0) {
            this.setState({
                route:
                    '/CreateCorporateMembers/CorporateMemberId?=' + encryptAES(corporateMemberId) + "ViewCorp"
            });
            window.open('/CreateCorporateMembers/CorporateMemberId?=' + encryptAES(corporateMemberId) + "ViewCorp", '_blank');
        }
    }
    getMembershipPeriods = () => {
        this.membershipTaskListServices.GetMembershipPeriods(4).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let membershipPeriod = response.responseItem.responseContent;
                membershipPeriod = membershipPeriod.map((p, index) => ({
                    id: p.months > 12 ? index + 2 : index + 1, //index + 1 ,
                    name: `Ending ${moment(p.subScriptionEndDate).format(
                        "Do MMMM YYYY"
                    )} (${p.months} months)`,
                    startDate: p.subScriptionStartDate,
                    endDate: p.subScriptionEndDate,
                    months: p.months,
                    year: p.months > 12 ? index + 2 : index + 1
                }));
                this.setState({ membershipTenureList: membershipPeriod })
            }
        });
    };

    GetMembershipPeriods2 = (corporateMemberId, quotationDate) => {
        let request = {
            membershipTypeId: 4,
            quotationDate: quotationDate,
            corporateMemberId: corporateMemberId
        }
        this.membershipTaskListServices.GetMembershipPeriods2(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let membershipPeriod = response.responseItem.responseContent;
                membershipPeriod = membershipPeriod.map((p, index) => ({
                    id: p.months > 12 ? index + 2 : index + 1, //index + 1 ,
                    name: `Ending ${moment(p.subScriptionEndDate).format(
                        "Do MMMM YYYY"
                    )} (${p.months} months)`,
                    startDate: p.subScriptionStartDate,
                    endDate: p.subScriptionEndDate,
                    months: p.months,
                    year: p.months > 12 ? index + 2 : index + 1
                }));
                if (membershipPeriod[0].months > 7) {
                    membershipPeriod.forEach((element, index) => {
                        if (index === 0) {
                            element.id = index + 1;
                            element.year = index + 1;

                        } else {
                            element.id = membershipPeriod[index - 1].id + 1;
                            element.year = membershipPeriod[index - 1].year + 1;
                        }
                    })
                }
                else {
                    membershipPeriod.forEach((element, index) => {
                        if (index === 0) {
                            element.id = index + 1;
                            element.year = index + 1;
                        } else {
                            element.id = membershipPeriod[index - 1].id + 1;
                            element.year = membershipPeriod[index - 1].year + 1;
                        }
                    })
                }
                this.setState({ membershipTenureList: membershipPeriod })
            }
        });
    };

    deleteCorporateMemberFromTaskList = (value) => {
        // let request = {
        //   id: value,
        //   deletedBy: 1,
        //   appId: 115,
        // };
        let reqParam = [value, true];
        this.swalServices
          .Confirm(
            CommonSuccessMessages.Confirm_Question.replace("{0}", "Member"),
            CommonSuccessMessages.Confirm_Delete.replace("{0}", "Member"),
            CommonSuccessMessages.Yes_Text,
            CommonSuccessMessages.No_Text
          )
          .then((response) => {
            if (response) {
                this.membershipTaskListServices.DeleteTaskListDetailsByTaskId(reqParam)
                .then((response) => {
                  if (response.statusCode === 200) {
                    this.swalServices.Success("Deleted");
                     this.getAllTaskList();
                  } else {
                    this.swalServices.Error(response.message);
                  }
                  this.setState({ isLoading: false });
                });
            }
          });
      };
    

    render() {     
        return (
            <>
                <div className="main-body-section">
                    <div className="bg-white pl-8 pt-10 pb-12">
                        <div className="grid grid-cols-12 gap-6 w-full items-center">
                            <div className="2xl:col-span-6 lg:col-span-6 col-span-12 pr-7">
                                <h2 className="text-xl theme-color">Date</h2>
                                <div className="grid grid-cols-12 gap-6 items-center w-full">
                                    <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                                        <DateInput
                                            name="dateFrom"
                                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                            value={moment(this.state.pagination.dateFrom).format("YYYY-MM-DD")}
                                            handleChange={(e) => this.handleChange(e, "Filter_By_Date")}
                                        />
                                    </div>
                                    <div className=" 2xl:col-span-2 lg:col-span-2 col-span-12 w-full text-center">
                                        <h2 className="text-xl theme-color">To</h2>
                                    </div>
                                    <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                                        <DateInput
                                            name="dateTo"
                                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                            value={moment(this.state.pagination.dateTo).format("YYYY-MM-DD")}
                                            handleChange={(e) => this.handleChange(e, "Filter_By_Date")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                                <h2 className="pl-12 block text-xl font-medium  theme-color">
                                    Status
                                </h2>
                                <div className="pl-12 border-l-[1px] border-[#181818]">
                                    <DropdownSelect
                                        drpIdentity={"Status_Name"}
                                        optionArray={this.state.statusList}
                                        setFilterParameters={this.setFilterParameters.bind(this)}
                                        value={this.state.pagination.statusId}
                                    />
                                    {/* <button
                                        onClick={() => this.setState({ setRejectModal: true })}
                                    >
                                        Open Reject Modal
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-6 gap-4 pt-10">
                        <h2 className="col-start-1 col-end-3 text-3xl font-bold text-[#181818]">
                            {`Total:${this.state.totalResultes}`}
                        </h2>
                        <div className="col-end-7 col-span-3 grid grid-cols-3 items-center">
                            <caption className="text-[#181818] text-xl text-right pr-5">Search by Company </caption>
                            <input
                                id="default"
                                className="col-span-2 text-xl font-bold h-[55px] w-full text-[#181818] border-white"
                                type="text"
                                onChange={(e) => this.searchByName(e.target.value)}
                            />
                        </div>
                    </div>
                    <main className="pb-10">
                        <div className="w-full mx-auto">
                            <div className="relative">
                                <div>
                                    <div className=" mt-6">
                                        <Table
                                            columns={[
                                                { name: "registrationDate", title: "Registration Date" },
                                                { name: "fullName", title: "Name" },
                                                // { name: "email", title: "Email Address" },
                                                { name: "CompanyName", title: "Company Name" },
                                                // { name: "CompanyUEN", title: "Company UEN" },
                                                { name: "transactionTypeName", title: "Transaction Type" },
                                                { name: "statusName", title: "Status" },
                                                { name: "corporateMemberId", title: "Action" },
                                            ]}
                                            rows={this.state.membershipTaskList}
                                            isLoading={false}
                                            sortingColumns={["Status", "fullName", "email", "CompanyName", "CompanyUEN", "transactionTypeName", "statusName", "registrationDate"]}
                                            pagination={this.state.pagination}
                                            totalResultes={this.state.totalResultes}
                                            totalCount={this.state.totalResultes}
                                            setPagination={this.setPagination.bind(this)}
                                            customScope={[
                                                {
                                                    column: "corporateMemberId",
                                                    renderTableData: this.actions.bind(this),
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <div className="member-task-process">
                    <MembershipTaskListProcess
                        billingCategoryList={this.state.billingCategoryList}
                        updatedByList={this.state.updatedByList}
                        membershipTypeList={this.state.membershipTypeList}
                        revenueTypeList={this.state.revenueTypeList}
                        transactionTypeList={this.state.transactionTypeList}
                        membershipTenureList={this.state.membershipTenureList}
                        designationList={this.state.designationList}
                        taskListDetail={this.state.taskListDetail}
                        setAddeditModalOpen={this.state.setAddeditModalOpen}
                        validStateTaskListDetails={this.state.validStateTaskListDetails}
                        // Pricing Table Data
                        basePriceTable={this.state.basePriceTable}
                        allowableDiscountTable={this.state.allowableDiscountTable}
                        percentageDiscountTable={this.state.percentageDiscountTable}
                        systemCalculatedTable={this.state.systemCalculatedTable}
                        // Loader State
                        isLoadingPayment={this.state.isLoadingPayment}
                        isLoadingQuotation={this.state.isLoadingQuotation}
                        isLoadingInvoice={this.state.isLoadingInvoice}
                        isLoadingApprove={this.state.isLoadingApprove}
                        isEscalateLodaing={this.state.isEscalateLodaing}
                        // All Method
                        setModalOpen={() => this.setState({ setAddeditModalOpen: false })}
                        handleChange={this.handleChange.bind(this)}
                        setFilterParameters={this.setFilterParameters.bind(this)}
                        addEditMembershipTask={this.addEditMembershipTask.bind(this)}
                        // disablePaymentButton={this.state.disablePaymentButton}
                        handleChangeInPriceTable={this.handleChangeInPriceTable.bind(this)}
                        addEditMembershipTaskAndSendPaymentLink={this.addEditMembershipTaskAndSendPaymentLink.bind(this)}
                        sendQuotationEmailToCorporateMember={this.sendQuotationEmailToCorporateMember.bind(this)}
                        sendEscalateMailToMember={this.sendEscalateMailToMember.bind(this)}
                        validateField={this.validateField.bind(this)}
                        quotationDownload={this.quotationDownload.bind(this)}
                        disablePaymentButton={this.state.pagination.filterBySource}
                        navigateToViewCorporateMember={this.navigateToViewCorporateMember}
                        isReadyForSendPaymentLink={this.state.isReadyForSendPaymentLink}
                        corporateCreditTerms={this.state.corporateCreditTerms}
                    />

                    <SendForApproveModal
                        companyName={this.state.taskListDetail.companyName}
                        email={this.state.taskListDetail.email}
                        setSendApproveModal={this.state.setSendApprove}
                        setOpenModal={() => this.setState({ setSendApprove: false })}
                        onClose={(e) => {
                            e.stopPropagation();
                            this.setState({ setSendApprove: false });
                        }}
                    />
                    <SendForRejectModal
                        setSendApproveModal={this.state.setRejectModal}
                        setOpenModal={() => this.setState({ setRejectModal: false })}
                        onClose={(e) => {
                            e.stopPropagation();
                            this.setState({ setRejectModal: false });
                        }}
                    />

                    <SendPaymentLink
                        companyName={this.state.taskListDetail.companyName}
                        email={this.state.taskListDetail.email}
                        paymentLinkModal={this.state.setPaymentLink}
                        setOpenModal={() => this.setState({ setPaymentLink: false })}
                        onClose={(e) => {
                            e.stopPropagation();
                            this.setState({ setPaymentLink: false });
                        }}
                    />

                    <SendQuotationModal
                        companyName={this.state.taskListDetail.companyName}
                        email={this.state.taskListDetail.email}
                        setSendQuotationModal={this.state.sendQuotation}
                        setOpenModal={() => this.setState({ sendQuotation: false })}
                        onClose={(e) => {
                            e.stopPropagation();
                            this.setState({ sendQuotation: false });
                        }}
                    />

                    <EscalateModal
                        setSendInvoiceModal={this.state.sendEscalation}
                        setOpenModal={() => this.setState({ sendEscalation: false })}
                        onClose={(e) => {
                            e.stopPropagation();
                            this.setState({ sendEscalation: false });
                        }}
                    />
                </div>
            </>
        )
    }
}